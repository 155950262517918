'use client'

import { useRouter } from 'next/navigation'
import { Button } from 'src/components/Button/Button'

import { HeroSectionDescription } from 'src/views/Home/Guest/Hero/HeroSectionDescription'
import { HeroSectionTitle } from 'src/views/Home/Guest/Hero/HeroSectionTitle'
import { AppStoreRating } from 'src/views/Home/Guest/Hero/AppStoreRating'
import { HeroCarousel } from 'src/views/Home/Guest/Hero/HeroCarousel'

export const HeroSection = () => {
  const router = useRouter()

  const handleCTAClick = () => {
    obe.analytics.track(obe.events.landing_page_cta_pressed, {
      location: 'hero-block',
      route: 'quiz-before-paywall',
    })
    setTimeout(() => {
      router.push('/obe-quiz')
    }, 100)
  }

  return (
    <div className="relative">
      <div className="container relative mx-auto flex flex-col items-center gap-6 px-8 py-12">
        <div className="absolute left-4 right-4 top-0 -z-10 h-[150%] rounded-2.5xl bg-primary-20 lg:h-[200%]" />
        <HeroSectionTitle />
        <HeroSectionDescription />
        <Button onClick={handleCTAClick} variant='primary' className="w-[340px] uppercase">
          Start free trial
        </Button>
        <AppStoreRating />
      </div>

      <HeroCarousel />
    </div>
  )
}
