import { Icon } from 'src/components/Icon'
import { Typography } from 'src/components/Typography'
import { useResponsive } from 'src/hooks/useResponsive'
import { heroInfo } from 'src/views/Home/Guest/Hero/info'

export const HeroSectionDescription = () => {
  const { isSmallAndDown } = useResponsive()

  return isSmallAndDown ? (
    <div className="flex w-full max-w-fit flex-col gap-2 self-center">
      {heroInfo.map((entry, index) => (
        <div key={`hero-entry-${index}`} className="flex gap-[9px] text-left ">
          <Icon.PurpleCircleCheck />
          <Typography.Body2>{entry}</Typography.Body2>
        </div>
      ))}
    </div>
  ) : (
    <div className="px-4">
      <Typography.Body2>{heroInfo.join(', ')}</Typography.Body2>
    </div>
  )
}
