import { useCallback } from 'react'
import { A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Button } from 'src/components/Button/Button'
import { Typography } from 'src/components/Typography'
import { useSignupUrl } from 'src/hooks/useSignupUrl'
import { DirectusFile } from 'src/types/DirectusFile'
import { ArticleCard } from 'src/views/Home/Guest/ArticlesBlock/ArticleCard'
import { content } from 'src/views/Home/Guest/ArticlesBlock/content'
import { Link } from 'src/components/Link'

interface ArticlesBlockSectionProps {
  assets: DirectusFile[] | undefined
}

export const ArticlesBlockSection = ({ assets }: ArticlesBlockSectionProps) => {
  const signupUrl = useSignupUrl()

  const onFreeTrialClick = useCallback(() => {
    obe.analytics.track(obe.events.landing_page_cta_pressed, {
      location: 'body literacy',
      route: 'sign-up-flow',
    })
  }, [])

  return (
    <div className="flex flex-col gap-4 lg:container lg:mx-auto lg:gap-10 lg:!px-4">
      <div className="flex flex-col lg:gap-10">
        <Typography.Heading2 className="text-center">{content.title}</Typography.Heading2>

        {assets && assets.length ? (
          <>
            <div className="hidden gap-4 lg:grid lg:grid-cols-2">
              {assets.map((asset) => (
                <div key={asset.id} className="shadow-obe-neutral-xs">
                  <ArticleCard asset={asset} />
                </div>
              ))}
            </div>

            <div className="block lg:hidden">
              <Swiper
                modules={[Navigation, A11y]}
                slidesPerView={1.15}
                spaceBetween={16}
                loop
                style={{ paddingBlock: '32px', paddingInline: '16px' }}
              >
                {assets.map((asset) => (
                  <SwiperSlide key={asset.id}>
                    <ArticleCard asset={asset} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        ) : null}
      </div>

      <div className="flex justify-center px-4">
        <Button onClick={onFreeTrialClick} className="w-full sm:max-w-80">
          <Link
            to={signupUrl}
            synthetic={false}
            tracking={{ location: 'body literacy' }}
            className="text-white no-underline hover:no-underline"
          >
            Start Free Trial
          </Link>
        </Button>
      </div>
    </div>
  )
}
