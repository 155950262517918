import { Icon } from 'src/components/Icon'
import { Typography } from 'src/components/Typography'

export const AppStoreRating = () => {
  return (
    <div className="flex flex-row items-center justify-center">
      {[...Array(5)].map((_, index) => {
        return <Icon.RatingStar key={index} className="h-[19px] w-[19px]" />
      })}
      <div className="ml-[8px]">
        <Typography.Body3>4.8/5</Typography.Body3>
      </div>
    </div>
  )
}
