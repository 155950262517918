import { useMemo, useRef } from 'react'

import asset2Image from 'public/marketing-hero/asset2.png'
import asset4Image from 'public/marketing-hero/asset4.png'
import asset5Image from 'public/marketing-hero/asset5.png'

interface HeroCardProps {
  slideNumber: number
}
export const HeroCard = ({ slideNumber }: HeroCardProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const hero1 = '/marketing-hero/asset1'
  const hero3 = '/marketing-hero/asset3'

  const slug = useMemo(() => {
    switch (slideNumber) {
      case 1:
        return hero1
      case 2:
        return asset2Image.src
      case 3:
        return hero3
      case 4:
        return asset4Image.src
      case 5:
        return asset5Image.src
      default:
        return ''
    }
  }, [slideNumber])

  return (
    <div className={'relative w-full rounded-[20px] xl:h-[542px]'}>
      {[2, 4, 5].includes(slideNumber) ? (
        <img
          src={slug}
          alt={`hero image ${slideNumber}`}
          className={'size-full rounded-[20px] object-cover'}
        />
      ) : (
        <video
          ref={videoRef}
          muted={true}
          autoPlay={true}
          loop
          playsInline
          className={'size-full rounded-[20px] object-cover'}
        >
          <source type='video/webm' src={`${slug}.webm`} />
          <source type='video/mp4' src={`${slug}.mp4`} />
        </video>
      )}
    </div>
  )
}
