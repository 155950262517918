import Image from 'next/image'

import marketingHeroImage from 'public/assets/marketing-pt-banner.png'
import { Button } from 'src/components/Button/Button'
import { Typography } from 'src/components/Typography'
import { useRouter } from 'src/hooks/useRouter'

interface QuizPreviewCTAProps {
  linkTo: string
}

export const QuizPreviewCTA = ({ linkTo }: QuizPreviewCTAProps) => {
  const { push } = useRouter()

  const handleCTAClick = () => {
    obe.analytics.track(obe.events.landing_page_cta_pressed, {
      location: 'quiz-block',
      route: 'quiz-before-paywall',
    })
    setTimeout(() => {
      push(linkTo)
    }, 100)
  }

  return (
    <div className="mx-auto max-w-[1264px] flex-1 !px-4">
      <div
        className="flex flex-col justify-between gap-6 rounded-2.5xl bg-gradient-1000 !px-4 pt-6 lg:flex-row lg:gap-4
          lg:!px-10"
      >
        <section className="flex flex-col items-center justify-center gap-6 lg:items-start">
          <div className="flex flex-col gap-3 !text-center lg:!text-start">
            <Typography.Heading1 className="text-white">
              Get your personalized plan
            </Typography.Heading1>
            <Typography.Body3 className="text-white">
              Take our 2-minute quiz to get matched with an online training program that fits your
              goals.
            </Typography.Body3>
          </div>

          <Button
            onClick={handleCTAClick}
            variant={'secondary'}
            size='lg'
            className="max-w-full lg:max-w-[342px]"
          >
            Take 2-minute quiz
          </Button>
        </section>

        <section className="flex items-center justify-center">
          <Image src={marketingHeroImage.src} alt='Marketing hero' width={249} height={290} />
        </section>
      </div>
    </div>
  )
}
