import { client } from 'src/api'
import type {
  ObeCollection,
  CuratedObeCollection,
  CuratedObeCollectionCategory,
} from 'src/types/Collection'

export const getObeCollections = async () => {
  const { data } = await client.get<CuratedObeCollection>('/api/v4/obe_collections')
  return data
}

export const getPersonalizedObeCollections = async () => {
  const { data } = await client.get<CuratedObeCollection>('/api/v4/obe_collections/personalized')
  return data
}

export const getObeCollectionBySlug = async ({
  slug,
  page,
  perPage,
}: {
  slug: string
  page?: number
  perPage?: number
}) => {
  const { data } = await client.get<ObeCollection | null>('/api/v4/obe_collections/by_slug', {
    params: {
      slug,
      ...(page && { page }),
      ...(perPage && { perPage }),
    },
  })
  return data
}

export const getObeCuratedCollectionSlugs = async () => {
  const { data } = await client.get<{ slugs: string[] }>('/api/v4/obe_collections/all_slugs')
  return data
}

export const getCuratedObeCollectionCategories = async () => {
  const { data } = await client.get<CuratedObeCollectionCategory[]>(
    '/api/v4/curated_obe_collection_categories',
  )
  return data
}

export const getCuratedObeCollectionsByCategory = async ({ slug }: { slug: string }) => {
  const { data } = await client.post<CuratedObeCollection>('/api/v4/curated_obe_collections', {
    category_slug: slug,
    per_page: 100,
  })
  return data
}
