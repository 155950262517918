export const memberQuotes = [
  {
    id: 'member-quote-1',
    quote:
      '“Before obé, I hated working out. Now, I move my body six to seven days a week. I’m more productive, energetic, and happier!”',
    name: 'Christian M.',
    slug: 'member-quote-thumb-1',
  },
  {
    id: 'member-quote-2',
    quote:
      '“obé prioritizes listening to your body to figure out what works for you, and it’s taught me to be kinder to my body.”',
    name: 'Seema V.',
    slug: 'member-quote-thumb-2',
  },
  {
    id: 'member-quote-3',
    quote:
      '“I feel physically stronger, more confident in my clothes and pictures, mentally balanced, and have found happiness in movement again.”',
    name: 'Crystal T.',
    slug: 'member-quote-thumb-3',
  },
]
