import { useCallback } from 'react'
import { CollectionCardImage } from 'src/components/Card/CollectionCard/CollectionCardImage'
import { Link } from 'src/components/Link'
import { Typography } from 'src/components/Typography'
import type { ObeCollection } from 'src/types/Collection'

interface Props {
  collection: ObeCollection
}

export const CollectionCard = ({ collection }: Props) => {
  const handleClick = useCallback(() => {
    obe.analytics.track(obe.events.landing_page_block_item_pressed, {
      item: 'collections-block',
      itemDetails: collection?.slug ?? '',
    })
  }, [collection?.slug])

  return (
    <Link
      className="relative z-[3] flex flex-col gap-3 hover:no-underline"
      to={`/obe-collections/${collection.slug}`}
      action={obe.events.clicked_obe_collection}
      tracking={{ collectionName: collection.title, collectionId: collection.id }}
      onClick={handleClick}
    >
      <CollectionCardImage imageUrl={collection.thumbnailImageUrl} name={collection.title} />

      <Typography.Body3Bold className="line-clamp-1">{collection.title}</Typography.Body3Bold>
    </Link>
  )
}
