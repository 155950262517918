import { useUiState } from 'src/hooks/useStores'
import { InView } from 'react-intersection-observer'

export const withMarketingTracker = (id, Component) => {
  return (props) => {
    const { uiStore, setMarketingModulesViewed } = useUiState()

    const mapComponentVisibility = (id, inView) => {
      setMarketingModulesViewed({ ...uiStore?.marketingModulesViewed, [id]: inView })
    }

    React.useEffect(() => {
      if (!Object.prototype.hasOwnProperty.call(uiStore?.marketingModulesViewed, id)) {
        setMarketingModulesViewed({ ...uiStore?.marketingModulesViewed, [id]: false })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <InView
        as='div'
        triggerOnce
        threshold={0.75}
        onChange={(inView) => {
          if (inView) mapComponentVisibility(id, inView)
        }}
      >
        {({ ref }) => (
          <div ref={ref}>
            <Component {...props} />
          </div>
        )}
      </InView>
    )
  }
}
