import type { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

import { cloudflareLoader, widths } from 'src/utils/image'

interface Props extends HTMLAttributes<HTMLDivElement> {
  imageUrl: string
  name: string
}

export const CollectionCardImage = ({ imageUrl, name, className, ...props }: Props) => {
  return (
    <div className={twMerge('relative flex flex-col items-center', className)} {...props}>
      <img
        src={cloudflareLoader({ src: imageUrl, width: widths.card })}
        alt={`${name} collection`}
        className="absolute -top-3 -z-[2] w-10/12 rounded-2xl"
      />
      <div className="absolute -top-3 bottom-1 -z-[2] w-10/12 rounded-2xl bg-black/25 backdrop-blur-sm" />
      <img
        src={cloudflareLoader({ src: imageUrl, width: widths.card })}
        alt={`${name} collection`}
        className="absolute -top-2 -z-[1] w-11/12 rounded-2xl"
      />
      <div className="absolute -top-2 bottom-0 -z-[1] w-11/12 rounded-2xl bg-black/15 backdrop-blur-sm" />
      <img
        src={cloudflareLoader({ src: imageUrl, width: widths.card })}
        alt={`${name} collection`}
        className="w-full rounded-2xl object-cover"
      />
    </div>
  )
}
