import { Swiper, SwiperSlide } from 'swiper/react'

import { CollectionCard } from 'src/components/Card/CollectionCard/CollectionCard'
import { Typography } from 'src/components/Typography'
import { useObeCollectionsBySlugs } from 'src/views/Home/Guest/useObeCollectionsBySlugs'

const slugsToFetch = ['working-out-on-your-cycle', 'age-well', 'prenatal-hub', 'gym-hub'] as const

export const ObeCollectionsSection = () => {
  const { collections, isLoading } = useObeCollectionsBySlugs([...slugsToFetch])

  return !isLoading ? (
    <div className="grid grid-cols-1 gap-8">
      <Typography.Heading2 className="text-center">Tailor made for you</Typography.Heading2>

      <div className="lg:hidden">
        <Swiper
          slidesPerView={1.4}
          spaceBetween={16}
          style={{ paddingBlock: '16px', paddingInline: '16px' }}
        >
          {collections?.map((collection) => (
            <SwiperSlide key={collection.id}>
              <CollectionCard key={collection.id} collection={collection} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="container mx-auto hidden grid-cols-4 gap-4 lg:grid">
        {collections?.map((collection) => (
          <CollectionCard key={collection.id} collection={collection} />
        ))}
      </div>
    </div>
  ) : null
}
