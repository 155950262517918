import useSWR from 'swr'

import { getObeCollectionBySlug } from 'src/models/obeCollections'
import { ObeCollection } from 'src/types/Collection'

export function useObeCollectionsBySlugs(slugs: string[]) {
  const { data, ...restSWR } = useSWR('tailor-made-collections', async () => {
    const settled = await Promise.allSettled(slugs.map((slug) => getObeCollectionBySlug({ slug })))

    return settled
      .filter((result) => result.status !== 'rejected' && result.value)
      .map((result) => (result as PromiseFulfilledResult<ObeCollection>).value)
  })

  return {
    collections: data,
    ...restSWR,
  }
}
