import { useMemo } from 'react'
import { Link } from 'src/components/Link'
import { Typography } from 'src/components/Typography'
import { DirectusFile } from 'src/types/DirectusFile'
import { cloudflareLoader, widths } from 'src/utils/image'

interface ArticleCardProps {
  asset: DirectusFile
}

export const ArticleCard = ({ asset }: ArticleCardProps) => {
  const trackingData = useMemo(
    () => ({ item: 'articles-block', itemDetails: asset.title }),
    [asset?.title],
  )

  return (
    <Link
      to={asset.videoUrl}
      newTab
      rel='noopener noreferrer'
      action={obe.events.landing_page_block_item_pressed}
      tracking={trackingData}
    >
      <div className="flex items-center gap-4 rounded-xl bg-white !p-4 shadow-obe-neutral-xs">
        <img
          src={cloudflareLoader({ src: asset.url, width: widths.card })}
          alt={asset.title}
          className="flex h-full min-h-[68px] max-w-[98px] rounded-[5px] bg-green object-cover"
        />
        <Typography.Body3Bold
          dangerouslySetInnerHTML={{ __html: asset.description }}
          className="line-clamp-2 [&_p]:m-0"
        />
      </div>
    </Link>
  )
}
