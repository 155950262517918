import { Typography } from 'src/components/Typography'

export const HeroSectionTitle = () => {
  const TITLE = ['Personalized fitness', 'made for women'] as const

  return (
    <div>
      {TITLE.map((t, idx) => (
        <Typography.Display2
          key={`hero-title-${idx}`}
          className="bg-gradient-1000 bg-clip-text text-center text-transparent"
        >
          {t}
        </Typography.Display2>
      ))}
    </div>
  )
}
