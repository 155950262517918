import { withMarketingTracker } from './withMarketingTracker'
import { Typography } from 'src/components/Typography'
import { FullContainer } from 'src/components/Display/Container'

const SECTIONS = {
  title: 'Digital fitness you can trust',
  sections: [
    {
      title: 'On-demand workout classes',
      description:
        'From Sculpt and Strength to Pre/Postnatal, explore options for every stage and fitness level, all taught by expert instructors.',
    },
    {
      title: 'Progressive training programs',
      description:
        'Train smarter, not harder with goal-focused programs that guide you through months of workouts.',
    },
    {
      title: 'Metrics to understand your body',
      description:
        'Health is more than a number on the scale. Track metrics that matter with insights on healthspan, body composition, and metabolic health.',
    },
    {
      title: 'Personalized cycle insights',
      description:
        'Learn to take advantage of hormonal fluctuations for improved performance, mental clarity, and energy.',
    },
  ],
} as const

const ClassVarietyView = () => {
  return (
    <FullContainer>
      <div className="mx-auto mb-7 mt-12 flex flex-col gap-14 lg:max-w-[1064px] lg:flex-row">
        <div className="flex items-center justify-center overflow-hidden">
          <video autoPlay loop muted playsInline className="w-[255px] overflow-hidden">
            <source
              type='video/mp4; codecs=hvc1'
              src='/assets/landing-page/videos/homepage-hero-block2.mp4'
            />
            <source type='video/webm' src='/assets/landing-page/videos/homepage-hero-block.webm' />
          </video>
        </div>
        <div className="flex-1 flex-col items-center justify-center self-center lg:items-start lg:self-start">
          <div className="mb-8 ml-2.5 w-[95%] flex-col gap-4 text-left lg:gap-6">
            <Typography.Heading2 className="text-black">{SECTIONS.title}</Typography.Heading2>
          </div>
          <div
            className="lg:gap-[24px 3.5rem] flex grid-cols-2 flex-wrap items-center gap-6 p-0 lg:flex-col lg:flex-nowrap
              lg:items-start lg:pr-8"
          >
            {SECTIONS.sections.map((section) => (
              <div key={section.title} className="ml-[13px] flex w-full flex-row lg:ml-[15px]">
                <div className="w-1 rounded-[1000px] bg-primary-400" />
                <div className="flex w-full" key={section.title}>
                  <div className="flex-col justify-between !px-5">
                    <Typography.Body1Bold className="text-black">
                      {section.title}
                    </Typography.Body1Bold>
                    <Typography.Body2 className="text-black">
                      {section.description}
                    </Typography.Body2>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </FullContainer>
  )
}

export const ClassVarietySection = withMarketingTracker('varietyModuleTest', ClassVarietyView)
