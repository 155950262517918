import { useRef } from 'react'
import type { Swiper as TSwiper } from 'swiper'

import { SwiperCarousel } from 'src/components/Carousel/Swiper'
import { SwiperNavigationDots } from 'src/components/Carousel/SwiperNavigationDots'
import { Typography } from 'src/components/Typography'
import { CarouselSpeedInMilliseconds } from 'src/utils/carousel/constants'
import { memberQuotes } from 'src/views/Home/Guest/MemberQuotes/constants'

interface Props {
  marketing2024RedesignAssets: Record<string, string>
}

export const MemberQuotes = ({ marketing2024RedesignAssets }: Props) => {
  const swiperRef = useRef<TSwiper>()

  return (
    <div>
      <SwiperCarousel
        swiperRef={swiperRef}
        className="mx-auto max-w-[1260px] !px-4"
        carouselName='home-member-quote'
        loop
        itemsShown={1}
        showHeader={false}
        showArrows={false}
        displayNavigationButton
        alwaysDisplayNavigation
        effect='slide'
        slidesPerGroup={1}
        title='home-member-quote'
        initialSlideIndex={0}
        carouselMarginBottom={0}
        pagination={<SwiperNavigationDots dots={memberQuotes.length} className="sm:hidden" />}
        settings={{
          slidesPerGroup: 1,
          speed: CarouselSpeedInMilliseconds.FIFTEEN_HUNDRED,
        }}
      >
        {memberQuotes.map((quoteData) => (
          <div
            key={quoteData.id}
            className="mx-auto flex h-full flex-col gap-6 !px-4 min-[577px]:!px-[20%] lg:flex-row lg:!px-[18%]"
          >
            <img
              src={marketing2024RedesignAssets[quoteData.slug]}
              alt={quoteData.name}
              className="size-[100px] rounded-full object-cover md:size-[116px]"
            />
            <div className="flex flex-col gap-4">
              <Typography.Body2>{quoteData.quote}</Typography.Body2>
              <Typography.Body3>{quoteData.name}</Typography.Body3>
            </div>
          </div>
        ))}
      </SwiperCarousel>
    </div>
  )
}
