'use client'

import type { DirectusFile } from 'src/types/DirectusFile'
import { WomensHistory } from 'src/views/AboutUs/WomensHistory/WomensHistory'
import { ArticlesBlockSection } from 'src/views/Home/Guest/ArticlesBlock'
import { ClassVarietySection } from 'src/views/Home/Guest/ClassVarietySection'
import { HeroSection } from 'src/views/Home/Guest/Hero'
import { MemberQuotes } from 'src/views/Home/Guest/MemberQuotes'
import { ObeCollectionsSection } from 'src/views/Home/Guest/ObeCollections/ObeCollectionsSection'
import { PressTestimonialsSection } from 'src/views/Home/Guest/PressTestimonials'
import { QuizPreviewCTA } from 'src/views/Home/Guest/QuizPreviewCTA/QuizPreviewCTA'
import { WomensHealthStudySection } from 'src/views/Home/Guest/WomensHealthStudySection'

const WorkoutSection = dynamic(() =>
  import('src/views/Home/Guest/Workout/WorkoutSection').then((mod) => mod.WorkoutSection),
)

interface GuestLandingPageViewProps {
  assets: Record<string, string>
  workoutAssets: Record<string, string>
  marketing2024RedesignAssets: Record<string, string>
  articlesBlock: { directusFiles: DirectusFile[] }
  pressTestimonials: { directusFiles: DirectusFile[] }
}

export const GuestLandingPageView = ({
  assets,
  workoutAssets,
  marketing2024RedesignAssets,
  articlesBlock,
  pressTestimonials,
}: GuestLandingPageViewProps) => {
  return (
    <div className="flex flex-col gap-12 bg-gradient-light lg:gap-[100px]">
      <HeroSection />
      <MemberQuotes marketing2024RedesignAssets={marketing2024RedesignAssets} />
      <WomensHealthStudySection />
      <ClassVarietySection image={assets?.['three-devices-latest']} />
      <QuizPreviewCTA linkTo='/obe-quiz' />
      <WorkoutSection assets={workoutAssets} />
      <ObeCollectionsSection />
      <ArticlesBlockSection assets={articlesBlock?.directusFiles} />
      <WomensHistory />
      <PressTestimonialsSection assets={pressTestimonials?.directusFiles} />
    </div>
  )
}
