import { type ElementRef, useMemo, useRef } from 'react'
import type { Swiper as TSwiper } from 'swiper/types'

import { useMediaQuery } from 'src/hooks/window'
import { CarouselDelayInSeconds, CarouselSpeedInMilliseconds } from 'src/utils/carousel/constants'
import { HeroCard } from 'src/views/Home/Guest/Hero/HeroCard'
import { SwiperCarousel } from 'src/components/Carousel/Swiper'
import { useResponsive } from 'src/hooks/useResponsive'
import { breakpointMediaShort } from 'src/styles'

import 'swiper/css'

const slideNumbers = [1, 2, 3, 4, 5] as const

export const HeroCarousel = () => {
  const { screenInnerWidth } = useResponsive()
  const swiperContainerRef = useRef<ElementRef<'div'>>(null)
  const swiperRef = useRef<TSwiper>()

  const is1870AndUp = useMediaQuery('(min-width: 1870px)')
  const isLargeAndUp = useMediaQuery(breakpointMediaShort.largeAndUp)
  const isMediumAndUp = useMediaQuery(breakpointMediaShort.mediumAndUp)

  const slidesPerView = useMemo(() => {
    switch (true) {
      case isLargeAndUp:
        return screenInnerWidth * 0.0028
      case isMediumAndUp:
      default:
        return screenInnerWidth * 0.0045
    }
  }, [isLargeAndUp, isMediumAndUp, screenInnerWidth])

  return (
    <div ref={swiperContainerRef}>
      {is1870AndUp ? (
        <div className="mx-auto flex w-fit gap-4 xl:max-w-[1854px]">
          {slideNumbers.map((n, idx) => (
            <HeroCard key={`hero-card-${idx}`} slideNumber={n} />
          ))}
        </div>
      ) : (
        <div className={'relative xl:mx-auto xl:max-w-[1700px]'}>
          <SwiperCarousel
            carouselName='hero-carousel'
            settings={{
              loop: true,
              speed: CarouselSpeedInMilliseconds.TWENTY_EIGHT_HUNDRED,
              spaceBetween: 20,
              slidesPerView,
              autoplay: {
                delay: CarouselDelayInSeconds.NONE,
              },
              centeredSlides: false,
              centerInsufficientSlides: true,
            }}
            showHeader={false}
            showArrows={false}
            swiperRef={swiperRef}
            smoothScroll={true}
            showSeeMore={false}
            seeMoreLink={'false'}
            allowTouchMove={false}
          >
            {slideNumbers.map((n, idx) => (
              <HeroCard key={`hero-slide-${idx}`} slideNumber={n} />
            ))}
          </SwiperCarousel>
        </div>
      )}
    </div>
  )
}
